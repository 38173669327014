/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useStage } from '../../../hooks/useStage';
import PromocodesService from '../../../services/general/promocodes.service';
import CEPService from '../../../services/general/cep.service';

import {
  onlyNumbers,
  onlyString,
  removeSpacesBetween,
} from '../../../helpers/form/formatter';
import {
  validationData,
  validationEmail,
  validationEstimatedElectricBill,
  validationName,
  validationNumber,
  validationPhone,
  validationPostalCode,
} from '../../../helpers/form/validations';
import { getAddressPayload } from './helpers';

import { maskCep, maskCpfOrCnpj, maskPhone } from '../../../helpers/form/masks';

import { unMask } from '../../../helpers/form/unMask';

import { Checkbox } from '../../Checkbox';
import { InputCustom } from '../../InputCustom';
import { PrimaryButton } from '../../PrimaryButton';
import { UploadCustom } from '../../UploadCustom';
import { PostalCodeIsCorrectlyChecked } from '../../PostalCodeIsCorrectlyChecked';
import { LoadingCustomModal } from '../../Modals/LoadingCustomModal';
import { CurrencyInput } from '../../CurrencyInput';
import { useStateForProposal } from '../../../hooks/dataForProposal';
import { PostalCodeWithoutAddressAndNeighborhood } from '../../PostalCodeWithoutAddressAndNeighborhood';

import {
  Anchor,
  ButtonContainer,
  ContAddress,
  Content,
  ContInput,
  FormContainer,
  LinkContainer,
  NoHasBillContainer,
  Text,
  Title,
} from './styles';
import { SecondaryButton } from '../../SecondaryButton';
import { FiledOCRModal } from '../../Modals/FiledOCRModal';
import UploadSuccessButNotPdfFileModal from '../../Modals/UploadSuccessButNotPdfFileModal';
import { AddressNumber } from '../../PostalCodeWithoutAddressAndNeighborhood/addressNumber';

export const StageOne = ({
  isNewPropertyOrHolder = true,
  resumeHandler = () => {},
}) => {
  const { stagePayload, changePayload, sendPayloadGlobal } = useStage();
  const { options } = stagePayload;
  const { ocrSuccess } = options || {};
  const { setDataForProposal } = useStateForProposal();
  const navigate = useNavigate();

  const {
    name,
    phone_number,
    email,
    promocode,
    attachment,
    no_electricity_bill,
    postal_code_isCorrectly,
    estimated_electric_bill,
    postal_code,
    state,
    city,
    street,
    neighborhood,
    number,
    complement,
    terms_of_service,
    holder,
    document,
    utm_source,
    utm_medium,
    utm_campaign,
    utm_id,
    utm_term,
    utm_content,
    utm_extra_data,
  } = stagePayload;

  const [file, setFile] = useState({});
  const fileInputRef = useRef();

  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [modalOcrOpen, setModalOcrOpen] = useState(false);

  const [
    isOpenUploadSuccessButNotPdfFileModal,
    setIsOpenUpdloadSuccessButNotPdfFileModal,
  ] = useState(false);
  const [disabledPostalCode, setDisabledPostalCode] = useState(false);
  const [loading, setLoading] = useState(false);
  const { alreadyUsed } = email || '';
  const SUPPORTED_FORMATS = ['image/jpeg', 'image/png', 'application/pdf'];

  const handleBackRegister = () => {
    if (isNewPropertyOrHolder) {
      navigate('/imoveis');
      return;
    }
    window.localStorage.removeItem('floraenergia:auth');
    window.location.href = 'https://floraenergia.com.br/';
  };

  const handleName = (event) => {
    let status = 'accepted';

    const isNameValid = validationName(onlyString(event.target.value));

    if (!isNameValid) {
      status = 'refused';
    }

    changePayload({
      name: { ...name, value: onlyString(event.target.value), status },
    });
  };

  const blurName = () => {
    if (!stagePayload.name.value.length) {
      changePayload({
        name: { ...name, touched: true, status: 'refused' },
      });

      return;
    }

    changePayload({
      name: { ...name, touched: true },
    });
  };

  const handlePhoneNumber = (event) => {
    let status = 'accepted';

    const isPhoneNumberValid = validationPhone(event.target.value);

    if (!isPhoneNumberValid) {
      status = 'refused';
    }

    changePayload({
      phone_number: {
        ...phone_number,
        value: maskPhone(event.target.value),
        status,
      },
    });
  };

  const blurPhoneNumber = () => {
    if (!stagePayload.phone_number.value.length) {
      changePayload({
        phone_number: { ...phone_number, touched: true, status: 'refused' },
      });

      return;
    }

    changePayload({ phone_number: { ...phone_number, touched: true } });
  };

  const handleEmail = (event) => {
    let status = 'accepted';
    const { value } = event.target;

    if (alreadyUsed?.length && alreadyUsed.includes(value)) {
      changePayload({ email: { ...email, value, status: 'refused' } });
      return;
    }

    const isEmailValid = validationEmail(value);

    if (!isEmailValid) {
      status = 'refused';
    }

    changePayload({ email: { ...email, value, status } });
  };

  const blurEmail = () => {
    if (!stagePayload.email.value.length) {
      changePayload({ email: { ...email, touched: true, status: 'refused' } });
      return;
    }

    changePayload({ email: { ...email, touched: true } });
  };

  const handlePromocode = (event) => {
    setButtonDisabled(false);
    changePayload({
      promocode: { ...promocode, value: event.target.value, touched: false },
    });
  };

  const handleEstimatedElectricBill = (currentValue) => {
    const convertedValue = currentValue / 100;

    let status = 'accepted';

    const isEstimatedElectricBillValid =
      validationEstimatedElectricBill(convertedValue);

    if (!isEstimatedElectricBillValid) {
      status = 'refused';
    }

    changePayload({
      estimated_electric_bill: {
        ...estimated_electric_bill,
        value: currentValue,
        converted_value: convertedValue,
        status,
      },
    });
  };

  const blurEstimatedElectricBill = () => {
    if (stagePayload.estimated_electric_bill.value === 0) {
      changePayload({
        estimated_electric_bill: {
          ...estimated_electric_bill,
          touched: true,
          status: 'refused',
        },
      });
      return;
    }

    changePayload({
      estimated_electric_bill: { ...estimated_electric_bill, touched: true },
    });
  };

  const handlePostalCode = (event) => {
    const isPostalCodeValid = validationPostalCode(unMask(event.target.value));

    const payload = {
      postal_code: {
        ...postal_code,
        value: maskCep(event.target.value),
        status: isPostalCodeValid ? 'awaiting' : 'refused',
      },
      street: { ...street, value: '', status: 'awaiting' },
      number: { ...number, value: '', status: 'awaiting' },
      complement: { ...complement, value: '' },
    };
    changePayload(payload);
  };

  const blurPostalCode = () => {
    if (stagePayload.postal_code.value.length === 0) {
      changePayload({
        postal_code: { ...postal_code, touched: true, status: 'refused' },
      });
      return;
    }

    changePayload({ postal_code: { ...postal_code, touched: true } });
  };

  const blurAddress = () => {
    if (street.value.length === 0) {
      changePayload({
        street: { ...street, touched: true, status: 'refused' },
      });

      return;
    }

    changePayload({ street: { ...street, touched: true } });
  };

  const handleComplement = (event) => {
    let status = 'accepted';

    const isComplementValid = validationData(event.target.value);

    if (!isComplementValid) {
      status = 'refused';
    }

    changePayload({
      complement: { ...complement, value: event.target.value, status },
    });
  };

  const handlePromocodeBlur = async (event) => {
    if (!event?.target?.value) return;

    const response = await PromocodesService.getByCode(event.target.value);

    const hasPromocode = Boolean(response?.success);

    if (!hasPromocode) {
      changePayload({
        promocode: {
          ...promocode,
          value: event.target.value,
          status: 'refused',
          touched: true,
        },
      });
      setButtonDisabled(true);
      return;
    }
    setButtonDisabled(false);
    changePayload({
      promocode: {
        ...promocode,
        value: event.target.value,
        status: 'accepted',
        touched: true,
      },
    });
  };

  const blurComplement = () => {
    changePayload({ complement: { ...complement, touched: true } });
  };

  const handleNoElectricityBill = (event) => {
    // event.persist();
    changePayload({ no_electricity_bill: event.target.checked });
  };

  const handlePostalCodeIsCorrectly = (event) => {
    // event.persist();
    changePayload({ postal_code_isCorrectly: event.target.checked });
  };

  const handleStreet = (event) => {
    event.persist();

    const status = validationData(onlyString(event.target.value))
      ? 'accepted'
      : 'refused';

    changePayload({
      street: { ...street, value: onlyString(event.target.value), status },
    });
  };

  const getAddress = async (onlyCheckForExistence) => {
    try {
      setDisabledPostalCode(true);
      const data = await CEPService.get(unMask(postal_code.value));
      if (onlyCheckForExistence)
        return changePayload({ postalCodeExist: true });

      const addressPayload = getAddressPayload({
        ...data,
        postal_code: postal_code.value,
        postal_code_isCorrectly: false,
      });

      changePayload({ ...addressPayload, postalCodeExist: true });
    } catch (err) {
      if (onlyCheckForExistence) {
        return changePayload({
          postal_code: { ...postal_code, status: 'refused', touched: true },
          postalCodeExist: false,
          postal_code_isCorrectly: false,
        });
      }

      changePayload({
        postal_code: { ...postal_code, status: 'refused', touched: true },
        city: { value: '', status: 'awaiting', touched: false },
        state: { value: '', status: 'awaiting', touched: false },
        street: { value: '', status: 'awaiting', touched: false },
        neighborhood: { value: '', status: 'awaiting', touched: false },
        number: { value: '', status: 'awaiting', touched: false },
        postalCodeExist: false,
        postal_code_isCorrectly: false,
      });
    } finally {
      setDisabledPostalCode(false);
    }
  };

  const validEstimated = (estimatedElectricBill) => {
    if (estimatedElectricBill >= 40) return 'accepted';
    else return 'refused';
  };

  const removeAttachment = () => {
    setFile({});
    changePayload({ attachment: '', ocrSuccess: false });
    fileInputRef.current.value = '';
  };

  const handleCloseFiledOCRModal = () => {
    setModalOcrOpen(false);
  };

  const updateStateFile = (event) => {
    setFile(event.target.files);
  };

  const renderMessage = () => {
    if (email?.error && alreadyUsed.includes(email.value)) return email.error;

    return 'Por favor digite um e-mail válido';
  };

  const handleSubmitNextStage = async (event) => {
    event.preventDefault();

    const payloadSubmitted = {
      attachment,
      name: removeSpacesBetween(name.value),
      phone_number: unMask(phone_number.value),
      email: email.value,
      estimated_electric_bill: Math.ceil(
        estimated_electric_bill.converted_value
      ),
      promocode: promocode?.value,
      postal_code: unMask(postal_code.value),
      state: state.value,
      city: city.value,
      street: street.value,
      neighborhood: neighborhood.value,
      number: number.value,
      complement: complement.value,
      terms_of_service,
      stage: 1,
      utm_source,
      utm_medium,
      utm_campaign,
      utm_id,
      utm_term,
      utm_content,
      utm_extra_data,
    };

    // eslint-disable-next-line no-restricted-syntax

    await sendPayloadGlobal(payloadSubmitted, isNewPropertyOrHolder, {
      setLoading,
      resumeHandler,
      file,
      setFile,
      setIsOpenUpdloadSuccessButNotPdfFileModal,
      setModalOcrOpen,
      fileInputRef,
      setButtonDisabled,
      setDataForProposal,
    });
  };

  const continueRegisterWithoutBill = () => {
    changePayload({ no_electricity_bill: true });
    removeAttachment();
  };

  useEffect(() => {
    const common =
      (estimated_electric_bill?.status === 'accepted' &&
        city?.status === 'accepted' &&
        state?.status === 'accepted' &&
        street?.status === 'accepted' &&
        neighborhood?.status === 'accepted' &&
        number?.status === 'accepted' &&
        (postal_code?.status === 'accepted' || postal_code_isCorrectly)) ||
      !!attachment?.length;

    const verify = isNewPropertyOrHolder
      ? common
      : name?.status === 'accepted' &&
        phone_number?.status === 'accepted' &&
        email?.status === 'accepted' &&
        terms_of_service === true &&
        common;

    if (verify) {
      setButtonDisabled(false);
      return;
    }
    setButtonDisabled(true);
  }, [
    attachment,
    name.status,
    phone_number.status,
    email.status,
    estimated_electric_bill.status,
    city.status,
    state.status,
    street.status,
    neighborhood.status,
    number,
    postal_code.status,
    postal_code_isCorrectly,
    terms_of_service,
    isNewPropertyOrHolder,
    no_electricity_bill,
  ]);

  useEffect(() => {
    if (postal_code?.value?.length !== 9) return;
    const { oldPostalCode, postalCodeExist } = stagePayload;

    if (
      oldPostalCode === postal_code.value &&
      postal_code.status === 'accepted'
    ) {
      if (postalCodeExist === undefined) getAddress(true);
      return;
    }
    getAddress(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postal_code.value]);

  const isInputDisabled = (fieldName) => {
    const { postalCodeExist } = stagePayload;
    const fieldObj = stagePayload?.[fieldName] || {};
    const { touched, valueFromPostalCode, value } = fieldObj;
    if (valueFromPostalCode === undefined && !postalCodeExist) return false; // when postal code comes from db
    if (touched) {
      if (!valueFromPostalCode?.length) return false;
      else if (value?.length) return true;
    }
    return false;
  };

  return (
    <>
      <LoadingCustomModal isLoading={loading} />
      <FiledOCRModal
        isOpen={modalOcrOpen}
        setFile={setFile}
        setLoading={setLoading}
        onRequestClose={handleCloseFiledOCRModal}
        removeAttachment={removeAttachment}
        accept={SUPPORTED_FORMATS}
        continueRegisterWithoutBill={continueRegisterWithoutBill}
      />
      <UploadSuccessButNotPdfFileModal
        isOpen={isOpenUploadSuccessButNotPdfFileModal}
        setFile={setFile}
        setLoading={setLoading}
        onRequestClose={setIsOpenUpdloadSuccessButNotPdfFileModal}
        continueRegisterWithoutBill={continueRegisterWithoutBill}
      />

      <FormContainer onSubmit={handleSubmitNextStage}>
        <Content isNewPropertyOrHolder={!!isNewPropertyOrHolder}>
          {!isNewPropertyOrHolder && (
            <>
              <Title>Preencha e simule sua economia!</Title>
              <ContInput>
                <InputCustom
                  name='fullName'
                  type='text'
                  labelText='Nome completo *'
                  value={name?.value}
                  onChange={handleName}
                  onBlur={blurName}
                  hasError={name?.status === 'refused'}
                  hasTouched={name?.touched}
                  errorMessage='Por favor digite um nome completo'
                  requiredMessage='Nome é obrigatório'
                  maxLength={50}
                />

                <InputCustom
                  name='phone'
                  type='tel'
                  labelText='Celular *'
                  placeholder='(00) 9 0000-0000'
                  value={phone_number?.value}
                  onChange={handlePhoneNumber}
                  onBlur={blurPhoneNumber}
                  hasError={phone_number?.status === 'refused'}
                  hasTouched={phone_number?.touched}
                  errorMessage='Por favor digite um celular válido'
                  requiredMessage='Celular é obrigatório'
                  autoComplete='off'
                  maxLength={16}
                />

                <InputCustom
                  name='email'
                  type='email'
                  labelText='E-mail *'
                  value={email?.value}
                  onChange={handleEmail}
                  onBlur={blurEmail}
                  hasError={email?.status === 'refused'}
                  hasTouched={email?.touched}
                  errorMessage={renderMessage()}
                  requiredMessage='E-mail é obrigatório'
                />

                <InputCustom
                  name='promocode'
                  type='text'
                  labelText='Código promocional'
                  value={promocode?.value || ''}
                  onChange={handlePromocode}
                  onBlur={handlePromocodeBlur}
                  hasError={promocode?.status === 'refused'}
                  successMessage='Código válido'
                  errorMessage='Código inválido'
                  hasTouched={promocode?.touched}
                />
              </ContInput>
            </>
          )}

          <Text isNewPropertyOrHolder={!!isNewPropertyOrHolder}>
            Indique o imóvel para receber o desconto
          </Text>

          {!(Object.values(file).length || stagePayload?.attachment) && (
            <Checkbox
              id='no-has-electricity-bill'
              checked={no_electricity_bill}
              onChange={handleNoElectricityBill}
            >
              <label htmlFor='no-has-electricity-bill'>
                Não possuo a conta de luz.
              </label>
            </Checkbox>
          )}

          {no_electricity_bill ? (
            <NoHasBillContainer>
              <CurrencyInput
                type='text'
                labelText='Valor médio da conta de luz *'
                value={estimated_electric_bill?.value}
                onChange={handleEstimatedElectricBill}
                onBlur={blurEstimatedElectricBill}
                hasError={estimated_electric_bill?.status === 'refused'}
                hasTouched={estimated_electric_bill?.touched}
                errorMessage='Por favor digite um valor válido'
                max={99999999}
              />

              <ContAddress
                isEqual={
                  postal_code?.value.length === 9 &&
                  postal_code?.status === 'accepted' &&
                  !!isInputDisabled('neighborhood')
                }
              >
                <InputCustom
                  name='postal_code'
                  type='text'
                  labelText='CEP *'
                  value={postal_code?.value}
                  onChange={handlePostalCode}
                  onBlur={blurPostalCode}
                  hasError={
                    postal_code?.status === 'refused' &&
                    !postal_code_isCorrectly
                  }
                  hasTouched={postal_code?.touched}
                  errorMessage='Não encontramos o CEP'
                  requiredMessage='CEP obrigatório'
                  maxLength={9}
                  disabled={disabledPostalCode}
                />

                {postal_code?.value.length !== 9 && (
                  <LinkContainer>
                    <Anchor
                      href='https://buscacepinter.correios.com.br/app/endereco/index.php'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Não sei meu CEP
                    </Anchor>
                  </LinkContainer>
                )}

                {postal_code?.value?.length === 9 &&
                  postal_code?.status === 'refused' && (
                    <Checkbox
                      checked={postal_code_isCorrectly}
                      onChange={handlePostalCodeIsCorrectly}
                      id='cep-is-correctly'
                    >
                      <label>CEP informado está correto.</label>
                    </Checkbox>
                  )}

                {postal_code?.value.length === 9 &&
                  postal_code?.status === 'refused' &&
                  postal_code_isCorrectly && <PostalCodeIsCorrectlyChecked />}

                {postal_code?.value?.length === 9 &&
                  postal_code?.status === 'accepted' && (
                    <>
                      {!isInputDisabled('neighborhood') ? (
                        <PostalCodeWithoutAddressAndNeighborhood />
                      ) : (
                        <>
                          <InputCustom
                            type='text'
                            name='street'
                            labelText='Endereço'
                            value={street?.value}
                            onChange={handleStreet}
                            onBlur={blurAddress}
                            hasTouched={street?.touched}
                            disabled={isInputDisabled('street')}
                          />

                          <AddressNumber
                            changePayload={changePayload}
                            number={number}
                          />

                          <InputCustom
                            type='text'
                            name='complement'
                            labelText='Complemento'
                            value={complement?.value || ''}
                            onChange={handleComplement}
                            onBlur={blurComplement}
                            hasTouched={complement?.touched}
                          />
                        </>
                      )}
                    </>
                  )}
              </ContAddress>
            </NoHasBillContainer>
          ) : (
            <UploadCustom
              accept={SUPPORTED_FORMATS}
              file={file}
              resetFile={removeAttachment}
              updateState={updateStateFile}
              className='stageOne'
              nameFile={stagePayload?.attachment}
              disableUpload={ocrSuccess}
              fileInputRef={fileInputRef}
              continueRegisterWithoutBill={continueRegisterWithoutBill}
            />
          )}

          {!isNewPropertyOrHolder && (
            <Checkbox
              checked={terms_of_service}
              id='accept-terms'
              onChange={(event) =>
                changePayload({
                  terms_of_service: event.target.checked,
                })
              }
            >
              <label>
                Concordo com as{' '}
                <Anchor
                  href='https://floraenergia.com.br/politica-de-privacidade/'
                  target='_blank'
                  rel='noreferrer'
                >
                  política de privacidade
                </Anchor>{' '}
                e uso de cookies.
              </label>
            </Checkbox>
          )}

          <ButtonContainer>
            <SecondaryButton onClick={handleBackRegister}>
              Voltar
            </SecondaryButton>
            <PrimaryButton
              id={
                import.meta.env.REACT_APP_ENV === 'prod'
                  ? 'cadastro_plataforma2'
                  : ''
              }
              type='submit'
              disabled={buttonDisabled}
            >
              Avançar
            </PrimaryButton>
          </ButtonContainer>
        </Content>
      </FormContainer>
    </>
  );
};

StageOne.propTypes = {
  isNewPropertyOrHolder: PropTypes.bool,
  resumeHandler: PropTypes.func,
};
