import connection from './connections/main';

const updateMassiveStatus = async (contracts) => {
  const { err, data } = await connection.put(
    `/contracts/bulk-status`,
    contracts
  );

  if (err) {
    throw err;
  }

  return data;
};

const ContractsService = {
  updateMassiveStatus,
};

export default ContractsService;
