import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { filesize } from 'filesize';
import { IoReceiptOutline } from 'react-icons/io5';
import { AiOutlineDelete } from 'react-icons/ai';
import FaturaImg from '../../assets/images/icons/monochromatic-png/faturas 2.png';

import { toast } from 'react-toastify';

import { useStage } from '../../hooks/useStage';

import { LoadingCustomModal } from '../Modals/LoadingCustomModal';

import {
  Container,
  DeleteButton,
  LabelAttachment,
  Strong,
  UploadButtonContainer,
  UploadContainer,
  UploadContent,
} from './styles';
import FileIsLargeModal from '../Modals/FileIsLargeModal';
import transformBytes from '../../helpers/transformBytes';
import AttachmentsService from '../../services/attachments/attachments.service';
import { handlingFileName } from '../../utils/FileNameAdjustment';
import theme from '../../theme';
import { IconButton } from '@mui/material';

export const UploadCustom = ({
  accept = [],
  file,
  resetFile = () => {},
  updateState = () => {},
  className = '',
  nameFile = '',
  disableUpload = false,
  maxSize = parseInt(import.meta.env.REACT_APP_MAX_UPLOAD_SIZE, 10),
  fileInputRef = null,
  continueRegisterWithoutBill = () => {},
}) => {
  const { changePayload } = useStage();
  const [loading, setLoading] = useState(false);
  const regex = /\/[0-9]+-/g;
  const fileName = nameFile?.split(regex)?.[1];
  const [isOpenFileIsLargeModal, setIsOpenFileIsLargeModal] = useState(false);

  const isAccept = 'PDF, JPG, PNG';
  const saveAttachment = async (event) => {
    try {
      setLoading(true);
      const data = await AttachmentsService.uploadAttachment(
        event.target.files[0]
      );
      changePayload({ attachment: data });
    } catch (err) {
      resetFile({});
      toast.error('Erro ao salvar conta de luz');
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = (event) => {
    const fileSelected = event.target?.files[0];

    if (!accept.includes(fileSelected?.type)) {
      toast.error('Arquivo não suportado.');
      return;
    }

    if (fileSelected.size >= maxSize) {
      setIsOpenFileIsLargeModal(true);
      // eslint-disable-next-line react/prop-types
      fileInputRef.current.value = '';
      return;
    }

    updateState(event);
    saveAttachment(event);
  };

  return (
    <>
      <LoadingCustomModal isLoading={loading} />
      <FileIsLargeModal
        isOpen={isOpenFileIsLargeModal}
        handleFileUpload={handleFileUpload}
        handleClose={setIsOpenFileIsLargeModal}
        removeAttachment={resetFile}
        accept={accept}
        continueRegisterWithoutBill={continueRegisterWithoutBill}
      />
      <Container>
        {!disableUpload && (
          <>
            <UploadButtonContainer className={className}>
              <input
                id='file_upload'
                type='file'
                onChange={(event) => handleFileUpload(event)}
                accept={accept}
                ref={fileInputRef}
              />
              <label htmlFor='file_upload'>Enviar conta de luz</label>
            </UploadButtonContainer>

            <p>
              Arquivo suportado: <Strong>{isAccept}</Strong>. Tamanho máximo
              suportado: <Strong>4.00 MB</Strong>
            </p>
          </>
        )}

        {!Object.values(file)?.length && !nameFile?.length && (
          <p>
            Fique tranquilo&#40;a&#41;, você pode enviar sua conta de luz após o
            cadastro.
          </p>
        )}

        {(!!Object.values(file)?.length || !!nameFile?.length) && (
          <>
            <LabelAttachment>Arquivo anexado:</LabelAttachment>
            <UploadContainer>
              <UploadContent>
                <img
                  src={FaturaImg}
                  style={{ width: '1.8rem', height: '1.8rem' }}
                />
                <div>
                  <Strong isNameFile>
                    {!!Object.values(file)?.length && !nameFile?.length
                      ? handlingFileName(file[0]?.name)
                      : handlingFileName(fileName)}
                  </Strong>

                  {!!Object.values(file)?.length && (
                    <span>{filesize(file[0]?.size)}</span>
                  )}
                </div>
                <IconButton
                  aria-label='delete'
                  size='large'
                  onClick={resetFile}
                >
                  <AiOutlineDelete
                    fontSize='inherit'
                    style={{ color: '#5c835c !important' }}
                  />
                </IconButton>
              </UploadContent>
            </UploadContainer>
          </>
        )}
      </Container>
    </>
  );
};

UploadCustom.propTypes = {
  accept: PropTypes.array,
  file: PropTypes.object.isRequired,
  resetFile: PropTypes.func,
  updateState: PropTypes.func,
  nameFile: PropTypes.string,
  className: PropTypes.string,
  disableUpload: PropTypes.bool,
  maxSize: PropTypes.number,
  fileInputRef: PropTypes.any,
  continueRegisterWithoutBill: PropTypes.func,
};
